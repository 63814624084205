.header {
    background-color: #3d6399;
    color: #ffffff;
}

.navbar {
    height: 5em;
}

.navbar-brand {
    font-weight: bold;
    color: #ffffff;

}

.nav-link {
    color: #ffffff;
    font-weight: bold;
}