.body {
  
}

.Main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5em 4em 5em;
  
}

.MainSlogan {  
  font-weight: bold;
  padding: 2em;
  
  
}

.MainImg {
  
  height: auto;
  min-width: 250px;
  max-width: 50%;
  border-radius: 300px;
}

.elements {
  align-items: center;
  padding: 3em;
  min-height: 60vh;
  display: flex;
  justify-content: space-between;
  

}

.title {
  text-align: center;
}

.image {
  height: auto;
  min-width: 250px;
  max-width: 50%;
  border-radius: 300px;
  padding: 1em;
  
}

.content {
  width: 20em;
  text-align: justify;
  padding: 2em;
  display: inline-block;
}

.content p {
  font-size: 100%;
}

.dark {
  background-color: #e9ecf3;
}

.light {
  background-color: #ffffff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media screen and (max-width: 630px) {
  .Main {
    flex-direction: column-reverse;
    padding: 2em 3em 2em;
   
  }
  .elements {
    flex-direction: column-reverse;
  }

  .ourservices {
    flex-direction: column;
  }

  

    
  }



