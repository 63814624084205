.thanks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5em;
    min-height: 70vh;
}

.title {
    font-weight: bold;
}

.message {
    font-style: italic;
}

.link {
    font-size: large;
    font-weight: 900;
    text-decoration: none;
    color: black;
    text-decoration: underline;
}